import React from 'react';

interface ContextMenuProps {
  x: number;
  y: number;
  onAction: (action: string) => void;
  isLocked: boolean | undefined;
}

const ContextMenu: React.FC<ContextMenuProps> = ({ x, y, onAction, isLocked }) => {
  return (
    <div style={{ 
      position: 'absolute', 
      top: y, 
      left: x, 
      backgroundColor: 'white', 
      boxShadow: '0 2px 10px rgba(0,0,0,0.1)', 
      borderRadius: '0.25rem', 
      padding: '0.5rem',
      zIndex: 1000,
    }}>
      <button 
        onClick={() => onAction('delete')} 
        style={{ display: 'block', width: '100%', textAlign: 'left', padding: '0.25rem 0.5rem', background: 'none', border: 'none', cursor: 'pointer' }}
      >
        Delete
      </button>
      <button 
        onClick={() => onAction(isLocked ? 'unlock' : 'lock')} 
        style={{ display: 'block', width: '100%', textAlign: 'left', padding: '0.25rem 0.5rem', background: 'none', border: 'none', cursor: 'pointer' }}
      >
        {isLocked ? 'Unlock' : 'Lock'}
      </button>
      <button 
        onClick={() => onAction('cancel')} 
        style={{ display: 'block', width: '100%', textAlign: 'left', padding: '0.25rem 0.5rem', background: 'none', border: 'none', cursor: 'pointer' }}
      >
        Cancel
      </button>
    </div>
  );
};

export default ContextMenu;
