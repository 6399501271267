import React from 'react';

interface CreditsPopupProps {
  onClose: () => void;
}

const CreditsPopup: React.FC<CreditsPopupProps> = ({ onClose }) => {
  return (
    <div style={{
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      padding: '1rem',
      borderRadius: '0.5rem',
      boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
      zIndex: 1000,
      maxWidth: '80%',
      maxHeight: '80%',
      overflow: 'auto',
    }}>
      <h2 style={{ marginBottom: '1rem' }}>Credits</h2>
      <p><strong>Software Author:</strong> Constantine Bimplis</p>
      <p><strong>Year:</strong> 2024</p>
      <p><strong>Description:</strong> Euro Coin Canvas is an interactive web application that allows users to manipulate and arrange Euro coins and other items on a virtual canvas. It supports real-time collaboration, allowing multiple users to interact with the same canvas simultaneously.</p>
      <p><strong>Features:</strong></p>
      <ul>
        <li>Add and manipulate Euro coins and custom items</li>
        <li>Real-time collaboration</li>
        <li>Zoom and pan functionality</li>
        <li>Session sharing</li>
        <li>Lock and unlock items</li>
      </ul>
      <p><strong>Technologies Used:</strong> React, TypeScript, Socket.IO</p>
      <button onClick={onClose} style={{
        marginTop: '1rem',
        padding: '0.5rem 1rem',
        backgroundColor: '#f0f0f0',
        border: 'none',
        borderRadius: '0.25rem',
        cursor: 'pointer',
      }}>
        Close
      </button>
    </div>
  );
};

export default CreditsPopup;
