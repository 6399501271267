import React, { useState, useRef, useEffect, memo } from 'react';
import { CoinData } from './types';

interface CoinProps {
  coin: CoinData;
  onDragEnd: (position: { x: number; y: number }) => void;
  onContextMenu: (x: number, y: number) => void;
  scale: number;
  pan: { x: number; y: number };
}

const Coin: React.FC<CoinProps> = memo(({ coin, onDragEnd, onContextMenu, scale, pan }) => {
  const [isDragging, setIsDragging] = useState(false);
  const coinRef = useRef<HTMLDivElement>(null);
  const dragOffset = useRef({ x: 0, y: 0 });
  const position = useRef(coin.position);

  useEffect(() => {
    position.current = coin.position;
  }, [coin.position]);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (isDragging && !coin.isLocked) {
        const newPosition = {
          x: (e.clientX - dragOffset.current.x - pan.x) / scale,
          y: (e.clientY - dragOffset.current.y - pan.y) / scale,
        };
        position.current = newPosition;
        if (coinRef.current) {
          coinRef.current.style.transform = `translate(${newPosition.x}px, ${newPosition.y}px)`;
        }
      }
    };

    const handleMouseUp = () => {
      if (isDragging) {
        setIsDragging(false);
        onDragEnd(position.current);
      }
    };

    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, onDragEnd, coin.isLocked, scale, pan]);

  const handleMouseDown = (e: React.MouseEvent) => {
    if (e.button === 0 && !coin.isLocked) {
      const rect = coinRef.current!.getBoundingClientRect();
      dragOffset.current = {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      };
      setIsDragging(true);
      e.stopPropagation();
    }
  };

  const handleContextMenuClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onContextMenu(e.clientX, e.clientY);
  };

  return (
    <div
      ref={coinRef}
      style={{
        position: 'absolute',
        width: `${coin.size}px`,
        height: `${coin.size}px`,
        transform: `translate(${coin.position.x}px, ${coin.position.y}px)`,
        cursor: coin.isLocked ? 'not-allowed' : 'move',
        opacity: coin.isLocked ? 0.7 : 1,
        userSelect: 'none',
        transition: isDragging ? 'none' : 'transform 0.1s ease-out',
      }}
      onMouseDown={handleMouseDown}
      onContextMenu={handleContextMenuClick}
    >
      <img
        src={`/images/${coin.imageFile}`}
        alt={coin.value}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          pointerEvents: 'none',
          filter: coin.isLocked ? 'grayscale(50%)' : 'none',
        }}
      />
      {coin.isLocked && (
        <div style={{
          position: 'absolute',
          top: '0',
          right: '0',
          backgroundColor: 'rgba(128, 128, 128, 0.8)',
          borderRadius: '50%',
          padding: '2px',
          width: '20px',
          height: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          pointerEvents: 'none',
        }}>
          🔒
        </div>
      )}
    </div>
  );
});

export default Coin;
