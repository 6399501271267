import React from 'react';

interface ToolbarProps {
  onAddCoin: () => void;
  onZoomIn: () => void;
  onZoomOut: () => void;
  onResetView: () => void;
  onShare: () => void;
  onShowCredits: () => void;
  onEraseLines: () => void; // New prop
}

const Toolbar: React.FC<ToolbarProps> = ({ onAddCoin, onZoomIn, onZoomOut, onResetView, onShare, onShowCredits, onEraseLines }) => {
  return (
    <div style={{ position: 'absolute', top: '1rem', left: '1rem', zIndex: 10, display: 'flex', gap: '0.5rem' }}>
      <button onClick={onAddCoin} style={{ backgroundColor: 'green', color: 'white', padding: '0.5rem 1rem', borderRadius: '0.25rem' }}>Add Coin</button>
      <button onClick={onZoomIn} style={{ backgroundColor: 'blue', color: 'white', padding: '0.5rem', borderRadius: '0.25rem' }}>Zoom In</button>
      <button onClick={onZoomOut} style={{ backgroundColor: 'blue', color: 'white', padding: '0.5rem', borderRadius: '0.25rem' }}>Zoom Out</button>
      <button onClick={onResetView} style={{ backgroundColor: 'blue', color: 'white', padding: '0.5rem', borderRadius: '0.25rem' }}>Reset View</button>
      <button onClick={onShare} style={{ backgroundColor: 'purple', color: 'white', padding: '0.5rem', borderRadius: '0.25rem' }}>Share Session</button>
      <button onClick={onEraseLines} style={{ backgroundColor: 'red', color: 'white', padding: '0.5rem', borderRadius: '0.25rem' }}>Erase Lines</button> {/* Add this button */}
      <button onClick={onShowCredits} style={{ backgroundColor: 'orange', color: 'white', padding: '0.5rem', borderRadius: '0.25rem' }}>Credits</button>
    </div>
  );
};

export default Toolbar;
