import React from 'react';
import EuroCoinCanvas from './components/EuroCoinCanvas';

function App() {
  return (
    <div className="App">
      <EuroCoinCanvas />
    </div>
  );
}

export default App;
