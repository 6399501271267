import React from 'react';
import { coinTypes } from './types';

interface CoinSelectorProps {
  onSelectCoin: (coinType: typeof coinTypes[0]) => void;
  onClose: () => void;
}

const CoinSelector: React.FC<CoinSelectorProps> = ({ onSelectCoin, onClose }) => {
  return (
    <div style={{
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      padding: '1rem',
      borderRadius: '0.5rem',
      boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
      zIndex: 1000,
      maxWidth: '80%',
      maxHeight: '80%',
      overflow: 'auto',
    }}>
      <h2 style={{ marginBottom: '1rem' }}>Select a Coin or Item</h2>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '1rem' }}>
        {coinTypes.map((coinType) => (
          <div 
            key={coinType.value}
            onClick={() => { onSelectCoin(coinType); onClose(); }}
            style={{
              cursor: 'pointer',
              padding: '0.5rem',
              borderRadius: '0.25rem',
              backgroundColor: '#f0f0f0',
              textAlign: 'center',
            }}
          >
            <img 
              src={`/images/${coinType.imageFile}`} 
              alt={coinType.value}
              style={{ width: '50px', height: '50px', objectFit: 'contain' }}
            />
            <div>{coinType.value}</div>
          </div>
        ))}
      </div>
      <button onClick={onClose} style={{
        marginTop: '1rem',
        padding: '0.5rem 1rem',
        backgroundColor: '#f0f0f0',
        border: 'none',
        borderRadius: '0.25rem',
        cursor: 'pointer',
      }}>
        Close
      </button>
    </div>
  );
};

export default CoinSelector;
