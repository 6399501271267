import React, { useState, useRef, useEffect } from 'react';
import io from 'socket.io-client';
import Coin from './Coin';
import Toolbar from './Toolbar';
import CoinSelector from './CoinSelector';
import CreditsPopup from './CreditsPopup';
import ContextMenu from './ContextMenu';
import { coinTypes, CoinData, Rectangle } from './types';

const socket = io('/');

const EuroCoinCanvas: React.FC = () => {
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [coins, setCoins] = useState<CoinData[]>([]);
  const [scale, setScale] = useState(1);
  const [pan, setPan] = useState({ x: 0, y: 0 });
  const [isPanning, setIsPanning] = useState(false);
  const [isDrawingRectangle, setIsDrawingRectangle] = useState(false);
  const [rectangles, setRectangles] = useState<Rectangle[]>([]);
  const [contextMenu, setContextMenu] = useState<{ coinId: string; x: number; y: number } | null>(null);
  const [showCoinSelector, setShowCoinSelector] = useState(false);
  const [showCredits, setShowCredits] = useState(false);
  const canvasRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id') || Math.random().toString(36).substr(2, 9);
    setSessionId(id);

    socket.emit('join', id);

    socket.on('updateCanvas', (updatedData) => {
      setCoins(updatedData.coins);
      setScale(updatedData.scale);
      setPan(updatedData.pan);
      setRectangles(updatedData.rectangles);
    });

    socket.on('sessionLoaded', (sessionData) => {
      setCoins(sessionData.coins);
      setScale(sessionData.scale);
      setPan(sessionData.pan);
      setRectangles(sessionData.rectangles || []);
    });

    socket.on('coinAdded', (newCoin) => {
      setCoins(prevCoins => [...prevCoins, newCoin]);
    });

    socket.on('coinMoved', (updatedCoin) => {
      setCoins(prevCoins => prevCoins.map(coin =>
        coin.id === updatedCoin.id ? { ...coin, position: updatedCoin.position } : coin
      ));
    });

    socket.on('coinDeleted', (coinId) => {
      setCoins(prevCoins => prevCoins.filter(coin => coin.id !== coinId));
    });

    socket.on('coinLockChanged', (coinId, isLocked) => {
      setCoins(prevCoins => prevCoins.map(coin =>
        coin.id === coinId ? { ...coin, isLocked } : coin
      ));
    });

    socket.on('zoomUpdated', (newScale, newPan) => {
      setScale(newScale);
      setPan(newPan);
    });

    socket.on('rectangleDrawn', (newRectangles) => {
      setRectangles(newRectangles);
    });

    socket.on('panUpdated', (newPan) => {
      setPan(newPan);
    });

    socket.on('resetView', ({ scale, pan }) => {
      setScale(scale);
      setPan(pan);
    });

    return () => {
      socket.off('sessionLoaded');
      socket.off('coinAdded');
      socket.off('coinMoved');
      socket.off('coinDeleted');
      socket.off('coinLockChanged');
      socket.off('zoomUpdated');
      socket.off('rectangleDrawn');
      socket.off('panUpdated');
      socket.off('resetView');
      socket.off('updateCanvas');
    };
  }, []);

  const emitCanvasUpdate = (updateType: string, payload: any) => {
    if (sessionId) {
      socket.emit('canvasUpdate', { sessionId, updateType, payload });
    }
  };

  const handleAddCoin = (coinType: typeof coinTypes[0]) => {
    const canvasRect = canvasRef.current!.getBoundingClientRect();
    const newCoin: CoinData = {
      ...coinType,
      id: Date.now().toString(),
      position: {
        x: (Math.random() * (canvasRect.width - coinType.size) - pan.x) / scale,
        y: (Math.random() * (canvasRect.height - coinType.size) - pan.y) / scale,
      },
      isLocked: false,
    };
    setCoins(prevCoins => [...prevCoins, newCoin]);
    emitCanvasUpdate('addCoin', newCoin);
  };

  const handleContextMenuAction = (action: string) => {
    if (action === 'cancel') {
      setContextMenu(null);
      return;
    }
    if (action === 'delete') {
      socket.emit('deleteCoin', sessionId, contextMenu!.coinId);
    } else {
      const coin = coins.find(c => c.id === contextMenu!.coinId);
      socket.emit('changeCoinLock', sessionId, contextMenu!.coinId, !coin!.isLocked);
    }
    setContextMenu(null);
  };

  const handleZoom = (delta: number, clientX: number, clientY: number) => {
    setScale(prevScale => {
      const newScale = Math.max(0.5, Math.min(prevScale * (1 - delta * 0.001), 2));
      if (canvasRef.current) {
        const rect = canvasRef.current.getBoundingClientRect();
        const mouseX = clientX - rect.left;
        const mouseY = clientY - rect.top;
        const newPan = {
          x: pan.x - (mouseX / prevScale - mouseX / newScale) * prevScale,
          y: pan.y - (mouseY / prevScale - mouseY / newScale) * prevScale,
        };
        setPan(newPan);
        emitCanvasUpdate('zoom', { scale: newScale, pan: newPan });
      }
      return newScale;
    });
  };

  const handlePanStart = (e: React.MouseEvent) => {
    if (e.button === 1 || (e.button === 0 && e.altKey)) {
      setIsPanning(true);
    } else if (e.button === 0) {
      setIsDrawingRectangle(true);
      const rect = canvasRef.current!.getBoundingClientRect();
      const startX = (e.clientX - rect.left - pan.x) / scale;
      const startY = (e.clientY - rect.top - pan.y) / scale;
      setRectangles([{ x: startX, y: startY, width: 0, height: 0 }]);
    }
  };

  const handlePanMove = (e: React.MouseEvent) => {
    if (isPanning) {
      const newPan = {
        x: pan.x + e.movementX,
        y: pan.y + e.movementY
      };
      setPan(newPan);
      emitCanvasUpdate('pan', newPan);
    } else if (isDrawingRectangle) {
      const rect = canvasRef.current!.getBoundingClientRect();
      const currentRect = rectangles[0];
      const width = (e.clientX - rect.left - pan.x) / scale - currentRect.x;
      const height = (e.clientY - rect.top - pan.y) / scale - currentRect.y;
      const newRectangles = [{ ...currentRect, width, height }];
      setRectangles(newRectangles);
      emitCanvasUpdate('rectangles', newRectangles);
    }
  };

  const handlePanEnd = () => {
    if (isPanning) {
      setIsPanning(false);
    }
    if (isDrawingRectangle) {
      socket.emit('rectangleDrawn', rectangles);
      setIsDrawingRectangle(false);
    }
  };

  const shareUrl = () => {
    if (sessionId) {
      const url = `${window.location.origin}${window.location.pathname}?id=${sessionId}`;

      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(url).then(() => {
          alert('Session URL copied to clipboard!');
          window.location.assign(url);
        }).catch((err) => {
          console.error('Could not copy text: ', err);
          alert(`Copy this URL: ${url}`);
          window.location.assign(url);
        });
      } else {
        prompt('Copy this URL:', url);
        window.location.assign(url);
      }
    }
  };

  const eraseLines = () => {
    setRectangles([]);
    socket.emit('rectangleDrawn', []); // Sync with other sessions
  };

  const resetView = () => {
    const newScale = 1;
    const newPan = { x: 0, y: 0 };
    setScale(newScale);
    setPan(newPan);
    socket.emit('resetView', sessionId, { scale: newScale, pan: newPan });
  };

  return (
    <div style={{ width: '100%', height: '100vh', position: 'relative', backgroundColor: '#f0f0f0' }}>
      <Toolbar
        onAddCoin={() => setShowCoinSelector(true)}
        onZoomIn={() => handleZoom(-100, window.innerWidth / 2, window.innerHeight / 2)}
        onZoomOut={() => handleZoom(100, window.innerWidth / 2, window.innerHeight / 2)}
        onResetView={resetView}
        onShare={shareUrl}
        onShowCredits={() => setShowCredits(true)}
        onEraseLines={eraseLines}
      />
      <div
        ref={canvasRef}
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'white',
          position: 'relative',
          overflow: 'hidden',
          cursor: isPanning ? 'grabbing' : 'grab'
        }}
        onMouseDown={handlePanStart}
        onMouseMove={handlePanMove}
        onMouseUp={handlePanEnd}
        onMouseLeave={handlePanEnd}
        onWheel={(e) => handleZoom(e.deltaY, e.clientX, e.clientY)}
      >
        <div style={{
          transform: `scale(${scale}) translate(${pan.x / scale}px, ${pan.y / scale}px)`,
          transformOrigin: 'top left'
        }}>
          {rectangles.map((rect, index) => (
            <div
              key={index}
              style={{
                position: 'absolute',
                left: rect.x,
                top: rect.y,
                width: rect.width,
                height: rect.height,
                border: '2px solid red'
              }}
            />
          ))}
          {coins.map((coin) => (
            <Coin
              key={coin.id}
              coin={coin}
              onDragEnd={(newPosition) => {
                socket.emit('moveCoin', sessionId, coin.id, newPosition);
              }}
              onContextMenu={(x, y) => setContextMenu({ coinId: coin.id, x, y })}
              scale={scale}
	      pan={pan}
            />
          ))}
        </div>
      </div>
      {showCoinSelector && (
        <CoinSelector
          onSelectCoin={handleAddCoin}
          onClose={() => setShowCoinSelector(false)}
        />
      )}
      {showCredits && (
        <CreditsPopup onClose={() => setShowCredits(false)} />
      )}
      {contextMenu && (
        <ContextMenu
          x={contextMenu.x}
          y={contextMenu.y}
          onAction={handleContextMenuAction}
          isLocked={coins.find(c => c.id === contextMenu.coinId)?.isLocked}
        />
      )}
    </div>
  );
};

export default EuroCoinCanvas;
