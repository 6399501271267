export const coinTypes = [
  { value: '2 Euro', size: 100, imageFile: '2.png' },
  { value: '1 Euro', size: 90, imageFile: '1.png' },
  { value: '50 Cent', size: 80, imageFile: '50.png' },
  { value: '20 Cent', size: 70, imageFile: '20.png' },
  { value: '10 Cent', size: 60, imageFile: '10.png' },
  { value: '5 Cent', size: 50, imageFile: '5.png' },
  { value: '2 Cent', size: 40, imageFile: '2cent.png' },
  { value: '1 Cent', size: 30, imageFile: '1cent.png' },
  { value: 'Other 1', size: 80, imageFile: 'other1.png' },
  { value: 'Other 2', size: 80, imageFile: 'other2.png' },
  { value: 'Other 3', size: 80, imageFile: 'other3.png' },
  { value: 'Other 4', size: 80, imageFile: 'other4.png' },
];

export interface Rectangle {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface CoinData {
  id: string;
  value: string;
  size: number;
  imageFile: string;
  position: { x: number; y: number };
  isLocked: boolean;
}
